<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="41"
    viewBox="0 0 41 41"
  >
    <defs>
      <linearGradient
        id="ntb"
        x1="50%"
        x2="50%"
        y1="0%"
        y2="100%"
      >
        <stop
          offset="0%"
          stop-color="#00C5EA"
        />
        <stop
          offset="100%"
          stop-color="#0093D0"
        />
      </linearGradient>
    </defs>
    <path
      fill="url(#ntb)"
      fill-rule="nonzero"
      d="M20.5 13.53A6.265 6.265 0 1 1 20.5 1a6.265 6.265 0 0 1 0 12.53zm0-1.942a4.324
      4.324 0 1 0 0-8.647 4.324 4.324 0 0 0 0 8.647zM20.5 40a4.941 4.941 0 1 1 0-9.882
      4.941 4.941 0 0 1 0 9.882zm0-1.941a3 3 0 1 0 0-6 3 3 0 0 0 0 6zM5.28 27.809a4
      4 0 1 1 0-8 4 4 0 0 1 0 8zm0-1.662a2.338 2.338 0 1 0 0-4.676 2.338 2.338 0 0 0 0
      4.676zm30.44 1.662a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-1.662a2.338 2.338 0 1 0 0-4.676
      2.338 2.338 0 0 0 0 4.676zM20.3 15h.4a1.3 1.3 0 1 1 0 2.6h-.4a1.3 1.3 0 1 1 0-2.6zm0
      10.4h.4a1.3 1.3 0 1 1 0 2.6h-.4a1.3 1.3 0 1 1 0-2.6zm0-5.2h.4a1.3 1.3 0 1 1 0
      2.6h-.4a1.3 1.3 0 1 1 0-2.6zM8.677 18.948a.75.75 0 0 1-1.108-1.012l5.107-5.594a.75.75
      0 1 1 1.108 1.011l-5.107 5.595zm23 0l-5.108-5.595a.75.75 0 0 1 1.108-1.011l5.107
      5.594a.75.75 0 1 1-1.108 1.012z"
    />
  </svg>
</template>

<script>
export default {
  name: 'NodeTreeBlueIcon',
};
</script>
